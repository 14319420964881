import React from 'react'
import Layout from '../components/layout'
import VideoSingle from '../components/videoSingle'
import DoubleVidContainer from '../components/DoubleContainer'
import vidList from '../components/vidList'
import AnchorLink from 'react-anchor-link-smooth-scroll'


export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false
    };
    this.updatePredicate = this.updatePredicate.bind(this);
  }
  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({
      isDesktop: window.innerWidth > 620
    });
  }

  render() {
      const isDesktop = this.state.isDesktop;
      const video = vidList.vidList;
  return (
      <Layout header="false" noMaxWidth="true">
        <div className="myhero">
          <a href="/" className="link--kumya"><span data-letters="HOLYCULTURE">HOLYCULTURE</span></a>
          <a href="/about" className="feat-text"><i>Salt & light in all of life.</i></a>
          <AnchorLink href="#recent-videos" id="recent-videos" className="nounderline"><div className="arrow-down"></div></AnchorLink>
        </div>
        <div className="transition-up center">
        <p>
          <span stle={{ fontFamily: 'Cabin', textTransform: 'uppercase', fontSize: 'xx-large' }}>Welcome to HolyCulture!</span>
          <br /><br />
          Check out the <a href="/blog">blog</a> or the <a href="/about">about</a> page! It's a work in progression!
        </p>
        {/*<h1 className="mb0 uppercase">Recent Videos</h1>*/}
        </div>
          {isDesktop ? (
            <ul className="video-list">
              {/* <VideoSingle single="true" vidData={video[3]} /> */}
              <DoubleVidContainer vidData={video[4]} vidData2={video[3]} />
              {/*<VideoSingle vidData={video[2]} />*/}
            </ul>
            ) : (
            <ul className="video-list">
              <VideoSingle single="true" vidData={video[4]} />
              <VideoSingle single="true" vidData={video[3]} />
              {/*<VideoSingle single="true" vidData={video[0]} />
            <VideoSingle vidData={video[2]} />*/}
            </ul>
          )}
      </Layout>
    );
  }
}
