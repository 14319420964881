export default {
  vidList: [
    {
      date: "060119",
      title: "5 Ways Harry Potter is a Clearly Christian Story",
      subtitle: "Faith & Film",
      url: "https://www.youtube.com/watch?v=c0xWRclUbXc"
    },
    {
      date: "061219",
      title: "Stanley vs. Durbin",
      subtitle: "Learning from Geerhardus Vos",
      url: "https://www.youtube.com/watch?v=N4bLUNKJHGg"
    },
    {
      date: "061519",
      title: "Prejudiced Big Tech",
      subtitle: "The latest Christian Persecution from Pinterest",
      url: "https://www.youtube.com/watch?v=FDbKazKI-Vk"
    },
    {
      date: "061519",
      title: "HolyCulture Under Biden",
      subtitle: "What does it mean for the church?",
      url: "https://www.youtube.com/watch?v=JVDHO9A8LmY"
    },
    {
      date: "061519",
      title: "Happy Valentine's Day",
      subtitle: "Valentine Can Teach Us Today",
      url: "https://www.youtube.com/watch?v=OOkaIJHI2O0"
    }
  ]
}
