import React from 'react'
import ModalVideo from 'react-modal-video'

class VideoDouble extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal () {
    this.setState({isOpen: true})
  }

  render () {
    let video_id = this.props.passTo.url.split('v=')[1];
    let ampersandPosition = video_id.indexOf('&');
    if(ampersandPosition !== -1) {
      video_id = video_id.substring(0, ampersandPosition);
    }
    let vid_img = "url('https://img.youtube.com/vi/" + video_id + "/0.jpg'";
    return (
      <React.Fragment>
      <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={video_id} onClose={() => this.setState({isOpen: false})} />
      <div
      className="image-double"
      onClick={this.openModal}
      style={{ backgroundImage: vid_img }}
      target="_blank">
        <div className="image-hover">
          <div className="text">
            <div className="title">{this.props.passTo.title}</div>
            <div className="subtitle">{this.props.passTo.subtitle}</div>
          </div>
        </div>
      </div>
      </React.Fragment>
    )
  }

}

export default VideoDouble;
