import React from 'react';
import VideoDouble from '../components/videoDouble';

class DoubleVidContainer extends React.Component {

  render (props) {
    return (
      <li className="image-double-wrapper fade-in show animate">
        <VideoDouble passTo={this.props.vidData} />
        <VideoDouble passTo={this.props.vidData2} />
      </li>
    )
  }
}

export default DoubleVidContainer;
